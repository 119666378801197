/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();
$text-light: mix(#121212, #fff, $weight: 30);
$text: mix(#121212, #fff, $weight: 0.92);

:root {
  --primary: #820f30;
  --text-light: #b8b8b8;
  --text: #fdfdfd;
  --header: #212121;
  --background-body: #141421;
  --background-light: rgba(255, 255, 255, 0.04);
  --background-middle: rgba(255, 255, 255, 0.1);
}

$primary: var(--primary);
$textlight: var(--text-light);
$text: var(--text);
$header: var(--header);
$body: var(--background-body);
$backgroundLight: var(--background-light);
$backgroundMiddle: var(--background-middle);

$red-palette: (
  50: #fde3e5,
  100: #f9b9be,
  200: #f38d94,
  300: #ea636b,
  400: #e1474e,
  500: #d83435,
  600: #c92e35,
  700: #b62633,
  800: #a31d32,
  900: #820f30,
  
  contrast:
    (
      50: rgba(white, 0.87),
      100: rgba(white, 0.87),
      200: rgba(white, 0.87),
      300: rgba(white, 0.87),
      400: rgba(white, 0.87),
      500: rgba(white, 0.87),
      600: rgba(white, 0.87),
      700: rgba(white, 0.87),
      800: rgba(white, 0.87),
      900: rgba(white, 0.87),
      // ... continues to 900
    ),
);
$my-primary: mat.define-palette($red-palette, 900);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$border-ok-primary: mat.get-color-from-palette(mat.$green-palette, 500);
$border-ok-secondary: mat.get-color-from-palette(mat.$orange-palette, 500);

$my-theme: mat.define-dark-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
  )
);

@include mat.all-component-themes($my-theme);



html,
body {
  height: 100%;
  margin: 0;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif; //Roboto, 'Helvetica Neue', sans-serif;

}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  //background-color: #121212;
  background-color: var(--background-body);
}
.text-light {
  color: var(--text-light);
}
.text {
  color: var(--text);
}

.mat-form-field {
  .mat-icon {
    //input iconfarbe
    color: var(--text-light);
  }
  .mat-input-element {
    //eingabe Textfarbe
    color: var(--text);
  }
  .mat-form-field-outline-start {
    //border-radius
    border-radius: 25px 0 0 25px !important;
  }
  .mat-form-field-outline-end {
    //border-radius
    border-radius: 0 25px 25px 0 !important;
  }
  .mat-form-field-outline {
    //inputfeld outline farbe
    color: var(--primary);
  }
}



hr.line {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.flexbox {
  // Ausrichting in an der Mitte
  display: flex;
  justify-content: center;
  padding-right: auto;
  padding-top: 0px;
}

//
//Dialog und Imput fields
//
.mat-dialog-container {
  background: #171720;
  border-radius: 15px !important;
}

.cdk-overlay-pane {
  border-radius: 15px !important;

  &.border-green {
    border: solid 2px $border-ok-primary;
  }

  &.border-red {
    border: solid 2px $primary;
  }

  &.border-orange {
    border: 2px solid $border-ok-secondary;
  }
}

//Dialogfeld höhe
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  height: 100%;
}

.cdk-overlay-dark-backdrop{
  background: rgba(0, 0, 0, 0.5);
}